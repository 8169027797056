import { Coin } from './../components/Market/types';

export const coins: Coin[] = [
  {
    name: 'Bitcoin',
    symbol: 'BTC',
    balance: '2.8541',
    available: '62,970 USD',
    inOrder: '18,443,647.77 USD',
    totalDeposited: '20,153.25 USDT',
    apr: '154.37%',
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    balance: '10.8541',
    available: '28,970 USD',
    inOrder: '5,443,647.77 USD',
    totalDeposited: '12,153.25 USDT',
    apr: '120.50%',
  },
  {
    name: 'Ripple',
    symbol: 'XRP',
    balance: '500.8541',
    available: '5,970 USD',
    inOrder: '1,443,647.77 USD',
    totalDeposited: '2,153.25 USDT',
    apr: '98.45%',
  },
  {
    name: 'Litecoin',
    symbol: 'LTC',
    balance: '150.8541',
    available: '12,970 USD',
    inOrder: '3,443,647.77 USD',
    totalDeposited: '7,153.25 USDT',
    apr: '110.75%',
  },
  {
    name: 'Cardano',
    symbol: 'ADA',
    balance: '1,000.8541',
    available: '8,970 USD',
    inOrder: '2,443,647.77 USD',
    totalDeposited: '6,153.25 USDT',
    apr: '102.20%',
  },
  {
    name: 'Polkadot',
    symbol: 'DOT',
    balance: '200.8541',
    available: '3,970 USD',
    inOrder: '900,647.77 USD',
    totalDeposited: '1,153.25 USDT',
    apr: '95.60%',
  },
  {
    name: 'Binance Coin',
    symbol: 'BNB',
    balance: '50.8541',
    available: '18,970 USD',
    inOrder: '4,443,647.77 USD',
    totalDeposited: '9,153.25 USDT',
    apr: '107.30%',
  },
  {
    name: 'Chainlink',
    symbol: 'LINK',
    balance: '300.8541',
    available: '14,970 USD',
    inOrder: '2,943,647.77 USD',
    totalDeposited: '8,153.25 USDT',
    apr: '103.85%',
  },
  {
    name: 'Stellar',
    symbol: 'XLM',
    balance: '800.8541',
    available: '6,970 USD',
    inOrder: '1,643,647.77 USD',
    totalDeposited: '3,153.25 USDT',
    apr: '99.50%',
  },
  {
    name: 'Tether',
    symbol: 'USDT',
    balance: '2,000.8541',
    available: '1,970 USD',
    inOrder: '200,647.77 USD',
    totalDeposited: '500.25 USDT',
    apr: '85.90%',
  },
];