import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Flex,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';

import { slides } from '../../contents/slidesData';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slice = () => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const navigate = useNavigate();

  // Handle click navigation for each slide
  const handleSlideClick = (id: string) => {
    navigate(`/article/${id}`);
  };

  // Responsive positioning for navigation arrows
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '10px', md: '30px' });

  // Slider settings
  const settings = {
    infinite: true,
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box 
    // bg="#03091C" 
    color="white" py={10} px={6}>
      {/* Header */}
      <Flex justify="space-between" align="center" mb={6} mx="10%">
        <Heading as="h2" size="lg" fontFamily="Poppins">
          CrestsX Update
        </Heading>
        <Text color="rgba(158, 202, 255, 0.8)" cursor="pointer" onClick={() => navigate('/article')}>
          View all
        </Text>
      </Flex>

      <Text fontSize="lg" color="rgba(255, 255, 255, 0.7)" mb={6} mx="10%">
        Get to know what we are up to &gt;&gt;
      </Text>

      {/* Slider */}
      <Flex justifyContent="center" mx="10%">
        <Box position="relative" width="100%" maxW="1200px">
          {/* Left Arrow */}
          {/* <IconButton
            aria-label="left-arrow"
            variant="ghost"
            position="absolute"
            left={side}
            top={top}
            transform="translate(0%, -50%)"
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <BiLeftArrowAlt size="40px" />
          </IconButton> */}

          {/* Right Arrow */}
          {/* <IconButton
            aria-label="right-arrow"
            variant="ghost"
            position="absolute"
            right={side}
            top={top}
            transform="translate(0%, -50%)"
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <BiRightArrowAlt size="40px" />
          </IconButton> */}

          {/* Slider */}
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {slides.map((slide) => (
              <Box
                key={slide.id}
                p={4}
                cursor="pointer"
                onClick={() => handleSlideClick(slide.id)}
              >
                <Box
                  height="200px"
                  backgroundImage={`url(${slide.image})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  borderRadius="10px"
                  mb={4}
                />
                <Heading as="h3" size="md" fontFamily="Poppins" mb={2}>
                  {slide.title}
                </Heading>
                <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)">
                  {slide.description}
                </Text>
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>
    </Box>
  );
};

export default Slice;