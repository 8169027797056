import React from "react";
import { Box, Grid, GridItem, Text, Heading, Image, Flex, Button } from "@chakra-ui/react";

const UniversalFeatures = () => {
    return (
        <Box
            color="white"
            py={10}
            px={{ base: 10, md: 20, lg: 40 , xl: 60}}
            textAlign="center"
        >
            {/* Title Section */}
            <Heading as="h2" size="lg" mb={4}>
                Universal Features
            </Heading>
            <Text fontSize="md" mb={10} color="rgba(255, 255, 255, 0.7)">
                The ultimate exchange platform with every feature you need
            </Text>

            {/* Features Grid */}
            <Grid
                templateColumns={{ base: "1fr", md: "1fr", lg: "1fr", xl: "1fr 1fr" }}
                gap={6}
                textAlign="left"
            >
                {/* Feature 1 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                    rowSpan={{ base: 1, md: 1, lg: 1, xl: 2 }}
                    colSpan={1}
                >
                    <Heading size="md" mb={4}>
                        Comprehensive Trading Products
                    </Heading>
                    <Text fontSize={{ base: "sm" }} color="rgba(255, 255, 255, 0.8)" mb={4}>
                        CrestsX provides a complete suite of trading options, including Spot,
                        Perpetual, and Warrants, empowering users to implement diverse
                        trading strategies seamlessly within one platform.
                    </Text>
                    <Flex gap={2} wrap="wrap" mb={4}>
                        <Button size="sm" variant="outline" colorScheme="blue">
                            Derivatives Warrant
                        </Button>
                        <Button size="sm" variant="outline" colorScheme="blue">
                            Spot Orderbook
                        </Button>
                        <Button size="sm" variant="outline" colorScheme="blue">
                            Derivatives Perpetual
                        </Button>
                    </Flex>
                    <Image
                        src="/Banner/Spot_Trade_Beta.svg"
                        alt="Trading chart"
                        borderRadius="lg"
                        mt={4}
                    />
                </GridItem>

                {/* Feature 2 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                >
                    <Heading size="md" mb={4} mr="50%">
                        Non-Custodial Decentralized Trading
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        CrestsX's architecture allows users to maintain ownership of their
                        assets through a fully on-chain structure. No KYC, where all trading,
                        order creation, and matching occur on-chain.
                    </Text>
                </GridItem>

                {/* Feature 3 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                >
                    <Heading size="md" mb={4} mr="50%">
                        Ensure Deep Liquidity
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        CrestsX's liquidity leveraging mechanism aggregates the efficiency of
                        an CrestsX blocktrade orderbook liquidity with the depth of AMMs
                        liquidity, ensuring deep liquidity for orders of any size, near-zero
                        slippage, and the lowest fees.
                    </Text>
                </GridItem>

                {/* Feature 4 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                    display={{ base: "none", xl: "block" }}
                >
                    <Heading size="md" mb={4} mr="50%">
                        Faster and Efficient
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        With our Swift Node technique, transaction speeds are boosted by 50%,
                        and gas fees are reduced by 50%.
                    </Text>
                </GridItem>

                {/* Feature 5 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                    display={{ base: "none", xl: "block" }}
                >
                    <Heading size="md" mb={4} mr="50%">
                        AI-Powered Trading
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        AI Tools to empower traders with features like automated portfolio
                        management, personalized trading strategies, trade signal, market
                        review, and on-chain analysis.
                    </Text>
                </GridItem>

                {/* Feature 6 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                    display={{ base: "none", xl: "block" }}
                >
                    <Heading size="md" mb={4} mr="50%">
                        Extra Earnings on CrestsX
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        Earn incentives in tokens through trade and deposit. LP tokens from
                        other platforms can also be deposited, traded, and earn additional
                        rewards on top.
                    </Text>
                </GridItem>

                {/* Feature 7 */}
                <GridItem
                    bg="#1A2235"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    bgImage="/Background/BG_circle.svg"
                    borderRadius="lg"
                    p={6}
                    border="1px solid #243B5E"
                    display={{ base: "none", xl: "block" }}
                >
                    <Heading size="md" mb={4} mr="50%">
                        Permissionless Token Listing
                    </Heading>
                    <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" mr="50%">
                        Easily list any token without the need for approval or centralized
                        control.
                    </Text>
                </GridItem>
            </Grid>
        </Box>
    );
};

export default UniversalFeatures;