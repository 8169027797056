import React from 'react';
import { Stack, Box } from '@chakra-ui/react';
import StatusCard from './StatusCard';
import { statuses } from '../../contents/statusData';

const StatusSection: React.FC = () => {
  return (
    <Stack spacing={4} direction={['column', 'row']} justifyContent="center" mb={4} wrap="wrap" px={6}>
      {statuses.map((status, index) => (
        <Box key={index} minW="200px">
          <StatusCard status={status} />
        </Box>
      ))}
    </Stack>
  );
};

export default StatusSection;