import React from 'react';
import { Box, Heading, Text, Flex, Button } from '@chakra-ui/react';
import { Coin } from './types';

interface MainCoinProps {
  coin: Coin;
}

const MainCoin: React.FC<MainCoinProps> = ({ coin }) => {
  return (
    <Box bg="#2A3A4B" borderRadius="lg" p={6} color="white" mb={4}>
      <Heading as="h3" size="md" mb={4}>{coin.name}</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" wrap="wrap">
        <Text>Balance: {coin.balance} {coin.symbol}</Text>
        <Text>Available: {coin.available}</Text>
      </Flex>
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" wrap="wrap">
        <Text>In Order: {coin.inOrder}</Text>
        <Text>Total Deposited: {coin.totalDeposited}</Text>
      </Flex>
      <Text>APR: {coin.apr}</Text>
      <Button mt={4} colorScheme="blue">See Detail</Button>
    </Box>
  );
};

export default MainCoin;