import React from 'react';
import { Box, Heading, Text, Button, Stack, SimpleGrid, GridItem, Image, Link } from '@chakra-ui/react';
import Logo from '../../assets/CrestsXLogo.svg';

const Wording = () => {
  return (
    <Box
      bgSize="cover"
      bgPosition="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      // height="100vh"
      color="white"
      position="relative"
      mb="10%"
    >
      <Box
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        width={{ base: '90%', md: '50%' }} // Adjust width for mobile
        position="relative"
        top={{ base: '20', md: '20' }} // No upward movement on mobile
        mx="auto" // Center horizontally
      >
        <Link href="/">
          <Image src={Logo} alt="Logo" height="60px" mb={10} mx="auto" />
        </Link>
        <Heading as="h1" size={{ base: 'xl', md: '3xl' }} mb={8} fontFamily="Poppins" fontWeight="500">
          Liberalize your trading experiences
        </Heading>
        <Text
          fontSize={{ base: '0.8rem', md: '0.9rem' }}
          mb={16}
          fontFamily="Poppins"
          fontWeight="300"
          color="rgba(255, 255, 255, 0.7)"
        >
          The Fully On-Chain Orderbook for All Your Innovative and Secure Financial Services
        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          mb={16}
          justifyContent="center"
        >
          <Button
            variant="outline"
            size="md"
            fontFamily="Poppins"
            fontWeight="300"
            color="white"
            borderColor="white"
            _hover={{ borderColor: "#1E88E5" }}
            onClick={() => window.open('https://crestsx.vercel.app/')}
          >
            Start Trading
          </Button>
          {/* <Button
            variant="outline"
            size="md"
            fontFamily="Poppins"
            fontWeight="300"
            color="white"
            borderColor="white"
            _hover={{ borderColor: "#1E88E5" }}
          >
            Portfolio
          </Button> */}
        </Stack>
        <Image
          src="/Banner/mascos_trade.svg"
          w={['100%', '100%', '100%']} // Full width on small, 80% on medium, 50% on large screens
          mx="auto" // Centers horizontally
          display="block" // Ensures it's treated as a block element for proper centering
          mt={{ base: '0', sm: '0' , md:'-40'}}  // Optional: Add margin at the top if needed
          mb={20}
        />
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} className="stats">
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Total Trading Volume
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              $123,456,789
            </Text>
          </GridItem>
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Total Deposited
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              $123,456,789
            </Text>
          </GridItem>
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Tokens
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              99+
            </Text>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Wording;