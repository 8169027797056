// slidesData.ts
export const slides = [
  {
    id: '1',
    image: '/Banner/Spot_Trade_Beta.svg',
    title: 'Spot Trade Beta Testing Campaign',
    description: 'Join our beta testing campaign and explore the new features.',
    author: 'John Doe',
    date: '20 June 2023',
    tags: ['NFTs', 'Market Research']
  },
  {
    id: '2',
    image: '/Banner/Leveraged_Trading.svg',
    title: "Leveraged Trading 101: A Beginner's Guide to Boosting Your Trades",
    description: 'Learn the basics of leveraged trading and how to maximize your profits.',
    author: 'Jane Smith',
    date: '15 June 2023',
    tags: ['Bitcoin', 'Trading', 'Forecast']
  },
  {
    id: '3',
    image: '/Banner/How_to_started.svg',
    title: 'How to get started with CrestsX',
    description: 'A comprehensive guide to getting started with CrestsX.',
    author: 'Alice Johnson',
    date: '10 June 2023',
    tags: ['News', 'Ethereum', 'DeFi']
  },
  {
    id: '4',
    image: '/Banner/Spot_Trade_Beta.svg',
    title: 'Spot Trade Beta Testing Campaign',
    description: 'Join our beta testing campaign and explore the new features.',
    author: 'John Doe',
    date: '20 June 2023',
    tags: ['NFTs', 'Market Research']
  },
  {
    id: '5',
    image: '/Banner/Leveraged_Trading.svg',
    title: "Leveraged Trading 101: A Beginner's Guide to Boosting Your Trades",
    description: 'Learn the basics of leveraged trading and how to maximize your profits.',
    author: 'Jane Smith',
    date: '15 June 2024',
    tags: ['Bitcoin', 'Trading', 'Forecast']
  },
  {
    id: '6',
    image: '/Banner/How_to_started.svg',
    title: 'How to get started with CrestsX',
    description: 'A comprehensive guide to getting started with CrestsX.',
    author: 'Alice Johnson',
    date: '10 June 2023',
    tags: ['News', 'Ethereum', 'DeFi']
  },
  {
    id: '7',
    image: '/Banner/Spot_Trade_Beta.svg',
    title: 'Spot Trade Beta Testing Campaign',
    description: 'Join our beta testing campaign and explore the new features.',
    author: 'John Doe',
    date: '20 June 2023',
    tags: ['NFTs', 'Market Research']
  },
  {
    id: '8',
    image: '/Banner/Leveraged_Trading.svg',
    title: "Leveraged Trading 101: A Beginner's Guide to Boosting Your Trades",
    description: 'Learn the basics of leveraged trading and how to maximize your profits.',
    author: 'Jane Smith',
    date: '15 June 2023',
    tags: ['Bitcoin', 'Trading', 'Forecast']
  },
  {
    id: '9',
    image: '/Banner/How_to_started.svg',
    title: 'How to get started with CrestsX',
    description: 'A comprehensive guide to getting started with CrestsX.',
    author: 'Alice Johnson',
    date: '10 June 2023',
    tags: ['News', 'Ethereum', 'DeFi']
  },
  {
    id: '10',
    image: '/Banner/Spot_Trade_Beta.svg',
    title: 'Spot Trade Beta Testing Campaign',
    description: 'Join our beta testing campaign and explore the new features.',
    author: 'John Doe',
    date: '20 June 2023',
    tags: ['NFTs', 'Market Research']
  },
  {
    id: '11',
    image: '/Banner/Leveraged_Trading.svg',
    title: "Leveraged Trading 101: A Beginner's Guide to Boosting Your Trades",
    description: 'Learn the basics of leveraged trading and how to maximize your profits.',
    author: 'Jane Smith',
    date: '15 June 2024',
    tags: ['Bitcoin', 'Trading', 'Forecast']
  },
  {
    id: '12',
    image: '/Banner/How_to_started.svg',
    title: 'How to get started with CrestsX',
    description: 'A comprehensive guide to getting started with CrestsX.',
    author: 'Alice Johnson',
    date: '10 June 2023',
    tags: ['News', 'Ethereum', 'DeFi']
  },
];