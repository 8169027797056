import React from 'react';
import { Box, Text, SimpleGrid, Divider, Heading ,Image} from '@chakra-ui/react';

const KeyHighlights = () => {
  return (
    <Box
    //   bgImage="url('/path-to-your-background-image.jpg')" // Replace with your background image path
    //   bgSize="cover"
    //   bgPosition="center"
      color="white"
      py={10}
      px={6}
    >
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        mb={20}
        fontFamily="Poppins"
        fontWeight="600"
      >
        Merging Centralized and Decentralized Exchange
      </Heading>
      
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} maxW="1200px" mx="auto">
        {/* Left Column */}
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            YOUR KEY YOUR COIN
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Non-custodial wallet<br />
            Your assets, your control.<br />
            No KYC
          </Text>
        </Box>

        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            FAST AND COST EFFICIENT
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Swift node optimization<br />
            50% lower gas fees<br />
            TPS 50% faster
          </Text>
        </Box>

        {/* Second Row */}
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            TRANSPARENCY
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Fully on-chain orderbook<br />
            Trade, order, match, fees<br />
            Run on Layer-1
          </Text>
        </Box>

        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            POWERFUL TRADING
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Universal features<br />
            Deep liquidity leveraging<br />
            Advance order type
          </Text>
        </Box>

        {/* Third Row */}
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            AI POWERED TRADING
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Trade signal<br />
            Auto portfolio adjustment<br />
            Personalized trading
          </Text>
        </Box>

        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2} color="#A0C9FF">
            EXTRA EARNING
          </Text>
          <Divider borderColor="rgba(255, 255, 255, 0.5)" mb={4} />
          <Text fontSize="sm" color="gray.300">
            Deposit interest from fees<br />
            Tradable deposit LP token<br />
            And earn on top
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default KeyHighlights;