import React from 'react';
import { Box, Flex, VStack, Heading , Text} from '@chakra-ui/react';
import Banner from '../../components/Market/MarketBanner';
import StatusSection from '../../components/Market/StatusSection';
import MainCoin from '../../components/Market/MainCoin';
import CryptocurrenciesTable from '../../components/Market/CryptocurrenciesTable';
import { coins } from '../../contents/coinData';

const Market: React.FC = () => {
  return (
    <Box py={10} px={6} bg="#0B0E11" color="white">
      <Banner />
      <Box mt={10}>
        <Heading as="h2" size="lg" mb={6} textAlign="center">
          CrestsX Deposit & Withdraw
        </Heading>
      </Box>
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" mt={10} wrap="wrap">
        <Box w={{ base: 'full' }} mx='10%'>
          <StatusSection />
          <MainCoin coin={coins[0]} />
          <CryptocurrenciesTable coins={coins} />
        </Box>
      </Flex>
    </Box>
  );
};

export default Market;