// pages/index.tsx
import { Grid, GridItem, Box, Text, Button, Select, Input, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react";

const Exchange = () => {
  return (
    <Grid
      templateAreas={`
        "header header"
        "sidebar main"
        "footer footer"
      `}
      gridTemplateRows={"60px 1fr 30px"}
      gridTemplateColumns={"300px 1fr"}
      h="100vh"
      gap="4"
      color="whiteAlpha.900"
      fontWeight="bold"
    >
      <GridItem pl="2" bg="gray.900" area={"header"}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={4}>
          <Text>BTC/USDT</Text>
          <Box display="flex" alignItems="center">
            <Text mx={2}>24h Change: -1.23%</Text>
            <Text mx={2}>24h Volume (USD): 500,000</Text>
          </Box>
        </Box>
      </GridItem>
      <GridItem pl="2" bg="gray.800" area={"sidebar"}>
        <Box p={4}>
          <Text mb={4}>Order Book</Text>
          {/* Add Order Book Items here */}
          <Box>
            {/* Order Book Entries */}
            <Text>Price | Amount | Total</Text>
            {/* Repeat similar blocks for each order */}
            <Box display="flex" justifyContent="space-between">
              <Text>10,000.00</Text>
              <Text>0.111</Text>
              <Text>100.00</Text>
            </Box>
            <Box display="flex" justifyContent="space-between" color="red.500">
              <Text>15,000.1</Text>
              <Text>0.111</Text>
              <Text>100.00</Text>
            </Box>
          </Box>
        </Box>
      </GridItem>
      <GridItem pl="2" bg="gray.700" area={"main"}>
        <Box p={4}>
          <Box mb={4}>
            <Text>Buy</Text>
            <Select placeholder="Limit">
              <option value="market">Market</option>
              <option value="stop-limit">Stop-Limit</option>
            </Select>
          </Box>
          <Box mb={4}>
            <Text>Available: 0.000 USDT</Text>
            <Input placeholder="27,500 USDT" />
          </Box>
          <Box mb={4}>
            <Text>Limit</Text>
            <Input placeholder="BTC" />
          </Box>
          <Box mb={4}>
            <Text>Amount</Text>
            <Input placeholder="BTC" />
          </Box>
          <Box mb={4}>
            <Text>Percentage</Text>
            <Slider defaultValue={25}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
          <Box mb={4}>
            <Text>Total</Text>
            <Input placeholder="27,500 USDT" />
          </Box>
          <Button colorScheme="blue" width="100%">Connect Wallet</Button>
        </Box>
      </GridItem>
      <GridItem pl="2" bg="gray.900" area={"footer"}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Text>Open Orders (0)</Text>
          <Text>Order History</Text>
          <Text>Trade History</Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default Exchange;