import React from "react";
import { Box, Button, Heading, Flex, Link, Text } from "@chakra-ui/react";
import { FaDiscord, FaTelegram, FaTwitter } from "react-icons/fa";
import { SiMedium } from "react-icons/si";

const BottomSection = () => {
    return (
        <Box
            // bg="black"
            bgImage="/Background/web_bg.svg"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            color="white"
            py={10}
            // px={{ base: 4, md: 8, lg: 20 }}
            textAlign="center"
        // position="relative"
        // overflow="hidden"
        >
            {/* Wavy Background */}
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                zIndex={-1}
                opacity={0.2}
            />

            {/* CTA Section */}
            <Flex justify="center" align="center" direction="column" mb={10}>
                <Button
                    bg="#1A2235"
                    color="white"
                    fontSize={{ base: "md", md: "lg" }}
                    py={6}
                    px={8}
                    borderRadius="full"
                    _hover={{ opacity: 0.9 }}
                    mb={4}
                    onClick={() => {
                        window.open('https://crestsx.vercel.app/', '_blank');
                    }}
                >
                    Start Trading
                </Button>
            </Flex>

            {/* Community Section */}
            <Heading size="lg" mb={6}>
                JOIN CRESTSX COMMUNITY
            </Heading>

            {/* Social Links */}
            <Flex
                justify="center"
                gap={4}
                wrap="wrap"
                direction={{ base: "column", md: "row" }}
                align="center"
            >
                <Button
                    as={Link}
                    href="https://discord.com/invite/fjPe68DqN8"
                    target="_blank"
                    leftIcon={<FaDiscord />}
                    variant="outline"
                    colorScheme="whiteAlpha"
                    fontSize="sm"
                    px={6}
                    bg="black"
                >
                    Join Our Discord
                </Button>
                <Button
                    as={Link}
                    href="https://x.com/crestsx"
                    target="_blank"
                    leftIcon={<FaTwitter />}
                    variant="outline"
                    colorScheme="whiteAlpha"
                    fontSize="sm"
                    px={6}
                    bg="black"
                >
                    Follow Us on X
                </Button>
                <Button
                    as={Link}
                    href="https://medium.com/@CrestsX"
                    target="_blank"
                    leftIcon={<SiMedium />}
                    variant="outline"
                    colorScheme="whiteAlpha"
                    fontSize="sm"
                    px={6}
                    bg="black"
                >
                    Follow on Medium
                </Button>
                <Button
                    as={Link}
                    href="https://t.me/crestsx_official"
                    target="_blank"
                    leftIcon={<FaTelegram />}
                    variant="outline"
                    colorScheme="whiteAlpha"
                    fontSize="sm"
                    px={6}
                    bg="black"
                >
                    Join Our Telegram
                </Button>
            </Flex>
        </Box>
    );
};

export default BottomSection;