import React, { useState } from 'react';
import { Box, Heading, Text, Image, Link, Flex, Tag, SimpleGrid, Input, Select, Button } from '@chakra-ui/react';
import { slides } from '../../contents/slidesData'; // Adjust the import path as necessary

const AllArticles = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 9;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(event.target.value);
  };

  const sortedSlides = slides.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const filteredSlides = sortedSlides.filter(slide => {
    const matchesSearch = slide.title.toLowerCase().includes(searchTerm.toLowerCase()) || slide.author.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTags = selectedTag === '' || slide.tags.includes(selectedTag);
    return matchesSearch && matchesTags;
  });

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredSlides.slice(indexOfFirstArticle, indexOfLastArticle);

  const totalPages = Math.ceil(filteredSlides.length / articlesPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const uniqueTags = Array.from(new Set(slides.flatMap(slide => slide.tags)));

  return (
    <Box bg="#03091C" color="white" py={10} px={6}>
      <Flex mx="10%" mb={6} align="center">
        <Heading as="h2" size="lg" mr={20}>
          Articles
        </Heading>
        <Input
          placeholder="Search by title or author"
          value={searchTerm}
          onChange={handleSearchChange}
          mr={4}
        />
        <Select placeholder="Filter by tag" value={selectedTag} onChange={handleTagChange} maxWidth="200px">
          {uniqueTags.map((tag, index) => (
            <option key={index} value={tag}>
              {tag}
            </option>
          ))}
        </Select>
      </Flex>
      <SimpleGrid columns={[1, null, 3]} spacing={10} mx="10%">
        {currentArticles.map((slide, index) => (
          <Link key={index} href={`/article/${slide.id}`} _hover={{ textDecoration: 'none' }}>
            <Box
              bg="#1E2A38"
              borderRadius="lg"
              overflow="hidden"
              p={4}
              _hover={{ bg: "#162230" }}
            >
              <Image src={slide.image} alt={slide.title} mb={4} />
              <Heading as="h3" size="md" mb={2}>
                {slide.title}
              </Heading>
              <Text color="rgba(255, 255, 255, 0.7)" mb={2}>
                by {slide.author}
              </Text>
              <Text color="rgba(255, 255, 255, 0.5)" mb={4}>
                {slide.date}
              </Text>
              <Flex gap={2} mb={4}>
                {slide.tags.map((tag, tagIndex) => (
                  <Tag key={tagIndex} size="sm" variant="solid" colorScheme="blue">
                    {tag}
                  </Tag>
                ))}
              </Flex>
              <Text color="rgba(255, 255, 255, 0.7)">
                {slide.description}
              </Text>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
      <Flex justify="center" mt={6}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            mx={1}
            bg={index + 1 === currentPage ? 'blue.500' : ''}
            color="white"
            _hover={{ bg: 'blue.400' }}
          >
            {index + 1}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default AllArticles;