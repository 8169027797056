export const CONFIG = {
    STAKING_CONTRACT_ADDRESS: '0x2011dbCf2375e808e488D021798308643216C5A4', // Replace with your staking contract address
    POOL_ADDRESS: '0x07A4f63f643fE39261140DF5E613b9469eccEC86', // Example value, replace with actual
    ROUTER_ADDRESS: '0xE592427A0AEce92De3Edee1F18E0157C05861564', // Example value, replace with actual
    WRAP_ADDRESS: '0x2D37F4d6Fe47AD315584C6334486EDdCa3F2Cc14', // Example value, replace with actual
    DECIMALS: 18,
    TARGET_CHAIN_ID: 3502, // JFIN Testnet Chain ID
    NETWORK_NAME:'JFIN',
    EXPLORERS_NAME: 'JFIN Explorer',
    RPC_URL: 'https://rpc.jfinchain.com', // Example RPC URL for JFIN testnet
    CHAIN_NAME: 'JFIN Testnet',
    CHAIN_SYMBOL: 'JFIN',
    BLOCK_EXPLORER_URL: 'https://explorer.jfinchain.com', // Example value, replace with actual
    TEST_NET: true,
  };