import React, { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
  Input,
  Checkbox,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Coin } from './types';

interface CryptocurrenciesTableProps {
  coins: Coin[];
}

const CryptocurrenciesTable: React.FC<CryptocurrenciesTableProps> = ({ coins }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFavorites, setShowFavorites] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleFavoritesChange = () => {
    setShowFavorites(!showFavorites);
  };

  const toggleFavorite = (coinName: string) => {
    setFavorites((prev) =>
      prev.includes(coinName) ? prev.filter((name) => name !== coinName) : [...prev, coinName]
    );
  };

  const filteredCoins = coins.filter((coin) => {
    const matchesSearch = coin.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFavorites = !showFavorites || favorites.includes(coin.name);
    return matchesSearch && matchesFavorites;
  });

  return (
    <Box bg="#1E2A38" borderRadius="lg" p={6} color="white" overflowX="auto">
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          w={{ base: '100%', md: '30%' }}
          mr={{ base: 0, md: 4 }}
          mb={{ base: 4, md: 0 }}
        />
        <Checkbox isChecked={showFavorites} onChange={handleFavoritesChange}>
          Show Favorites
        </Checkbox>
      </Flex>
      {isMobile ? (
        <Accordion allowToggle>
          {filteredCoins.map((coin, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Checkbox
                      isChecked={favorites.includes(coin.name)}
                      onChange={() => toggleFavorite(coin.name)}
                      mr={2}
                    />
                    {coin.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box>
                  <Text>Balance: {coin.balance}</Text>
                  <Text>Available: {coin.available}</Text>
                  <Text>In Order: {coin.inOrder}</Text>
                  <Text>Total Deposited: {coin.totalDeposited}</Text>
                  <Text>APR: {coin.apr}</Text>
                  <Flex mt={4} justifyContent="space-between">
                    <Button colorScheme="blue">Trade</Button>
                  </Flex>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Table variant="simple" colorScheme="blue">
          <Thead>
            <Tr>
              <Th>Favorites</Th>
              <Th>Coin</Th>
              <Th>Balance</Th>
              <Th>Available</Th>
              <Th>In Order</Th>
              <Th>Total Deposited</Th>
              <Th>APR</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredCoins.map((coin, index) => (
              <Tr key={index}>
                <Td>
                  <Checkbox
                    isChecked={favorites.includes(coin.name)}
                    onChange={() => toggleFavorite(coin.name)}
                  />
                </Td>
                <Td>{coin.name}</Td>
                <Td>{coin.balance}</Td>
                <Td>{coin.available}</Td>
                <Td>{coin.inOrder}</Td>
                <Td>{coin.totalDeposited}</Td>
                <Td>{coin.apr}</Td>
                <Td>
                  <Flex justifyContent="space-between">
                    <Button colorScheme="blue" mr={2}>Trade</Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default CryptocurrenciesTable;