import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Wording from '../components/Landing/Wording';
// import Banner from '../components/Landing/Banner';
import Slice from '../components/Landing/Slice'
// import InsideCrestx from '../components/Landing/InsideCrestx'
// import TradingJourney from '../components/Landing/TradingJourney'
// import ReferCrestsX from '../components/Landing/ReferCrestsX'
// import Faq from '../components/Landing/Faq'
// import StartStep from '../components/Landing/StartStep'
import KeyHighlights from '../components/Landing/KeyHighlights'
import UniversalFeatures from '../components/Landing/UniversalFeatures';
import BottomSection from '../components/Landing/BottomSection';
import Partner from '../components/Landing/Partner';
import CoreTeam from '../components/Landing/CoreTeam';

const Landing_page = () => {
    return (
        <>
            <Box
                color="white"
                p={0} // Remove padding to allow the image to fully cover the container
                m={0} // Remove margin to avoid spacing
                bgSize="cover" // Ensures the image covers the container fully
                bgImage="url('/Background/background.svg')" // Replace with your actual background image path
                bgPosition="center" // Centers the background image
                bgRepeat="no-repeat" // Ensures the background doesn't repeat
                backgroundAttachment="fixed"
                // w="100vw" // Optional: Ensures full width
                minH="100vh" // Ensures the minimum height is the full viewport
            >
                <Wording />
                <KeyHighlights />
                <UniversalFeatures/>

                {/* <Partner/> */}
                
                {/* <Banner /> */}
                <Slice />
                {/* <InsideCrestx /> */}
                {/* <TradingJourney /> */}
                {/* <ReferCrestsX /> */}
                {/* <Faq /> */}
                {/* <StartStep /> */}
                {/* <CoreTeam/> */}
                <BottomSection/>
            </Box>
        </>
    );
};

export default Landing_page;