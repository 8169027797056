import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Image, Flex, Tag, Button } from '@chakra-ui/react';
import { slides } from '../../contents/slidesData'; 

const Article = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();

  const article = slides.find((slide) => slide.id === articleId);

  if (!articleId || !article) {
    return (
      <Flex bg="#03091C" color="white" py={10} px={6} align="center" justify="center" height="100vh">
        <Box textAlign="center">
          <Heading as="h2" size="lg">
            Article not found
          </Heading>
          <Button mt={4} onClick={() => navigate('/article')}>
            Go to All Articles
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex bg="#03091C" color="white" py={10} px={6} align="center" justify="center" minHeight="100vh">
      <Box maxWidth="800px" textAlign="center">
        <Image src={article.image} alt={article.title} mb={4} mx="auto" />
        <Heading as="h2" size="lg" mb={4}>
          {article.title}
        </Heading>
        <Text color="rgba(255, 255, 255, 0.7)" mb={2}>
          by {article.author}
        </Text>
        <Text color="rgba(255, 255, 255, 0.5)" mb={4}>
          {article.date}
        </Text>
        <Flex gap={2} mb={4} justify="center">
          {article.tags.map((tag, index) => (
            <Tag key={index} size="sm" variant="solid" colorScheme="blue">
              {tag}
            </Tag>
          ))}
        </Flex>
        <Text color="rgba(255, 255, 255, 0.7)">
          {article.description}
        </Text>
      </Box>
    </Flex>
  );
};

export default Article;