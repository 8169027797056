import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { Status } from './types';

interface StatusCardProps {
  status: Status;
}

const StatusCard: React.FC<StatusCardProps> = ({ status }) => {
  return (
    <Box bg="#1E2A38" borderRadius="lg" p={6} color="white" textAlign="center" w="full">
      <Heading as="h3" size="md" mb={2}>{status.title}</Heading>
      <Text fontSize="xl" mb={1}>{status.value}</Text>
      <Text>{status.unit}</Text>
    </Box>
  );
};

export default StatusCard;