import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing_page from './pages/Landing_page';
import About from './pages/about/About_Page';
import AllArticles from './pages/article/All_Article';
import Article from './pages/article/Article';
import Market from './pages/market/Market';
import Exchange from './pages/trade/Exchange';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing_page />} />
      <Route path="/about" element={<About />} />
      <Route path="/article" element={<AllArticles />} />
      <Route path="/article/:articleId" element={<Article />} />
      <Route path="/market" element={<Market />} />
      <Route path="/exchange" element={<Exchange />} />
    </Routes>
  );
};

export default AppRoutes;