import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Text,
  Link
} from '@chakra-ui/react';

const faqs = [
  {
    question: 'What is CrestsX?',
    answer: 'CrestsX is an next generation place to trad cryptocurrencies operate fully on- chain,transparency and security for the user experience.On Crestsx, you can trade tokens precisely, efficiency, and confidently. Provides a full range of features such as spot trading, futures, options, and more.'
  },
{
  question: 'What is difference between Orderbook and AMMs?',
    answer: 'There are two major types of DEXes: orderbooks and automated market makers (AMM). While AMMs allow users to trade with a pool of tokens, our technology enhances order book performance by offering precise trade execution, improved liquidity matching, and reduced slippage. Our advanced system ensures optimal price discovery and a superior trading experience by efficiently matching buy and sell orders, providing users with greater control and accuracy in their trades.'
},
{
  question: 'How can I get started with CrestsX?',
    answer: 'Connect Your Wallet: Begin by linking your Hot Wallet to the CrestsX platform. This ensures that you maintain full control over your funds at all times. Deposit Funds: Deposit your assets into CrestsX Smart Contract. Restassured that your funds remain under your control, as the wallets non-custodial nature allows you to authorize their use for trading without transferring ownership. Start Trading: Once your funds are deposited, you’re ready to trade. Select your preferred assets and begin trading with confidence using our advanced trading features and precise order book system.'
},
{
  question: 'Where are my assets stored?',
    answer: 'After depositing your funds into CrestsX, your assets are securely stored in a smart contract. You retain full control over your assets because you hold the private key (The platform does not have access to your private key). Noone else can access or move your funds—only you have the authority to manage them.'
},
{
  question: 'What is the liquidity incentive',
    answer: 'The liquidity incentive is a reward system designed to encourage users to contribute funds to a Crestsx order books liquidity. By providing liquidity, users help improve market depth and trading efficiency. In return, they receive rewards, often in the form of interest or other financial incentives, for their contribution. This helps ensure that there is enough liquidity for smooth and effective trading. You can get liquidity incentive by depositing funds into CrestsX or by trading'
},
];

const Faq = () => {
  return (
    <Box bg="#03091C" color="white" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6} textAlign="center" fontFamily="Poppins">
        FAQ
      </Heading>
      <Accordion allowMultiple>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} borderTop="none" borderBottom="1px solid rgba(158, 202, 255, 0.2)">
            <AccordionButton _expanded={{ bg: 'rgba(158, 202, 255, 0.1)', color: 'white' }}>
              <Box flex="1" textAlign="left">
                {faq.question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {faq.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {/* <Box textAlign="center" mt={6}>
        <Link color="rgba(158, 202, 255, 0.8)" href="#view-more">
          View more
        </Link>
      </Box> */}
    </Box>
  );
};

export default Faq;