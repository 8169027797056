import React from 'react';
import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';

const Banner: React.FC = () => {
  return (
    <Box bg="#03091C" px={6} display="flex" justifyContent="center">
      <Box
        bgGradient="radial-gradient(circle, #1A2F7D, #040711)"
        bgImage="/Banner/First_step.svg"
        borderRadius="lg"
        p={8}
        border="1px solid"
        borderColor="rgba(158, 202, 255, 0.5)"
        minW='80%'
        maxW="lg"
        maxH={150}
        textAlign="right"
      >
        
        <Heading as="h4" size="xl" mb={4}>Welcome to CrestsX</Heading>
        <Text fontSize="sm" mb={6}>The best place to trade and earn with cryptocurrencies</Text>
        {/* <Button colorScheme="blue" size="lg">Start Trading</Button> */}
      </Box>
    </Box>
  );
};

export default Banner;